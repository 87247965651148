import BaseActionCreator from "factory/BaseActionCreator";
import {CalendarEvents} from "actions/Events";

class CalendarActionCreator extends BaseActionCreator{

  constructor() {
    super("json");
  }

  loadSeatingChartById(seatingChartId){
    return this.get("/SeatingChart("+seatingChartId+")").then((data) => {
      this.dispatch({
        type: CalendarEvents.SEATING_CHART_BY_ID_LOADED,
        data
      });
      return data;
    });
  }

  loadSeatingChart(performanceId, clientId, deepLink = false) {
    let params = '';
    if(deepLink){
      params = "?deepLink=true"
    }
    return this.get(`/Performance(${performanceId})/seatingChart${params}`, null, {"clientId": clientId, "newCIRequest":true}).then(data => {
      let priceLevels = data.priceLevels;
      data.sections.forEach(section =>
        section.rows.forEach(row =>
          row.seats.forEach(seat => seat.priceLevel = priceLevels[seat.priceLevel])
        )
      );
      this.dispatch({type:CalendarEvents.SEATING_CHART_LOADED,data:data});
      return data;
    },(data)=>{
      this.dispatch({type:CalendarEvents.NO_SEATING_CHART,data});
    });
  }
}

export default new CalendarActionCreator();
