import React from 'react';

// Styled components
import styled from 'styled-components';

// Star icon
import { SvgStar } from 'components/common/icons/SvgIcons'

// Theme and colors
import { theme, colors } from '../../../../theme';

const Banner = styled.div`
	display: flex;
	padding: ${theme.padding.sm} ${theme.padding.lg};
	border-top: 1px solid ${colors.outlines.outline};
	border-bottom: 1px solid ${colors.outlines.outline};
	background-color: ${colors.surfaces.background};

	font-size: ${theme.fontSizes.md};
	font-weight: ${theme.fontWeight.semiBold};

	position: sticky;
	top: 0;
`;

const IconContainer = styled.span`
	margin-left: ${theme.margin.xs};
`

const TicketPromoBanner = ({ promoName }) => {
	return (
		<Banner>
			<p>{promoName} <IconContainer><SvgStar color="black" size="10" /></IconContainer> </p>
		</Banner>
	);
};

export default TicketPromoBanner;