import React from 'react';

import styled from 'styled-components';
import { theme } from '../../../../theme';


const GeneralInfo = styled.div`
	margin-top: ${theme.margin.xl};
	padding: 0 ${theme.padding.lg} ${theme.padding.md} ${theme.padding.lg};

	width: calc(100% - ${theme.padding.lg} * 2);

	display: flex;
`

const InfoBox = styled.div`
	width: ${({ width }) => width};
`

const InfoBoxHeading = styled.p`
	font-weight: ${theme.fontWeight.regular};
	font-size: ${theme.fontSizes.xs};

	margin-bottom: ${theme.margin.xxs}
`

const InfoBoxData = styled.p`
	font-weight: ${theme.fontWeight.bold};
	font-size: ${theme.fontSizes.sm};
`

const SelectSeatGeneralInfo = ({ selectedSeat }) => {
	return (
		<GeneralInfo>
			<InfoBox width="50%">
				<InfoBoxHeading>Section</InfoBoxHeading>
				<InfoBoxData>{selectedSeat.sectionName}</InfoBoxData>
			</InfoBox>

			<InfoBox width="25%">
				<InfoBoxHeading>Row</InfoBoxHeading>
				<InfoBoxData>{selectedSeat.row}</InfoBoxData>
			</InfoBox>

			<InfoBox width="25%">
				<InfoBoxHeading>Seat</InfoBoxHeading>
				<InfoBoxData>{selectedSeat.number}</InfoBoxData>
			</InfoBox>
		</GeneralInfo>
	);
};

export default SelectSeatGeneralInfo;