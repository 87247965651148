import { createContext } from 'react'

export const types = {
	public: 1,
	promo: 2,
}

export const TicketContext = createContext({
	selectedTicketType: types.public,
	setSelectedTicketType: () => {},

	selectedTicket: null,
	setSelectedTicket: () => {},

	setCollapsedAccordionOnce: () => {},
	collapsedAccordionOnce: false,

	selectedTicketTitle: null,
	setselectedTicketTitle:() => {}
})
