import { clone, deepEquals } from 'util/ObjectUtils'
import { NumberFormatter } from 'util/Formatter'

// arrays..
export const arrayEquals = (arr1, arr2) => {
	// shallow
	if (arr1.length !== arr2.length) return false
	for (let i = arr1.length; i--; ) {
		if (arr1[i] !== arr2[i]) return false
	}

	return true
}

export const arrayDeepEquals = (arr1, arr2) => {
	// console.log('ARRAYDEEPEQUALS');
	//if one of the array is empty and other is not then return false
	if ((!arr1 && arr2) || (arr1 && !arr2) || arr1.length !== arr2.length)
		return false
	//	console.log('ARRAYDEEPEQUALS***********');
	for (let i = arr1.length; i--; ) {
		if (typeof arr1[i] !== typeof arr2[i]) return false

		if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
			if (!deepEquals(arr1[i], arr2[i])) return false
		} else {
			// sort elements...
			if (typeof arr1[i] === 'number') {
				arr1[i] = sortNumerically(arr1[i])
				arr2[i] = sortNumerically(arr2[i])
			} else if (typeof arr1[i] === 'string') {
				arr1[i] = sort(arr1[i])
				arr2[i] = sort(arr2[i])
			}
			// compare...

			if (arr1[i] !== arr2[i]) {
				console.log('NOTEQUAL', arr1[i], arr2[i])
				return false
			}
		}
	}
	return true
}

// makes a deep clone of an array of objects
export const cloneArray = (arr) => {
	return arr.map((obj) => clone(obj))
}

export const sortNumerically = (numArray) => {
	console.log(numArray)
	return numArray.sort((a, b) => a - b)
}

export const sort = (array) => {
	return array.sort((a, b) => a.localeCompare(b))
}

// text
export const getRangeLabel = (max, min, symbol) => {
	return max > min
		? `${NumberFormatter.formatCurrency(min, symbol, 0)} - ${max}`
		: NumberFormatter.formatCurrency(min, symbol, 0)
}

export const getLabelfromCamelCase = (label) => {
	if (label.match(/.*[A-Z].*/)) {
		for (let i = 0; i < label.length; i++) {
			if (label[i].match(/[A-Z]/) !== null)
				return label.slice(0, i) + ' ' + label.slice(i)
		}
	}
	return label
}

export const detectElementByAttribute = (ele, attribute, value) => {
	if (!ele) return null
	let eleValue = ele.getAttribute(attribute)
	if (eleValue) return eleValue.indexOf(value) >= 0
}

// numbers

export const inRange = (num, min, max, include = true) => {
	if (!isNaN(num) || !isNaN(min) || !isNaN(max)) {
		if (include) return num >= min && num <= max
		else return num > min && num < max
	}
}

export const positionInRange = (position, rect) => {
	let { x, y } = position
	let { top, right, bottom, left } = rect

	return inRange(x, left, right, false) && inRange(y, top, bottom, false)
}
