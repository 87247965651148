import React, { useContext, useEffect, useState } from 'react'
// Styled components
import styled from 'styled-components'
// Theme and colors
import { theme, colors } from 'theme'
// Star icon
import { SvgStar } from 'components/common/icons/SvgIcons'
// Context
import { TicketContext, types } from './ticketContext/TicketContext'

const Ticket = styled.div`
	padding: ${theme.padding.lg};

	background-color: ${({ selected }) =>
		selected ? colors.surfaces.alternate : colors.white};
	transition: 200ms ease-in-out;

	border-top: 1px solid ${colors.borders.black};

	cursor: pointer;
	&:hover {
		background-color: ${({ selected }) =>
			selected ? colors.surfaces.alternate : colors.surfaces.background};
	}
`

const TicketHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;

	margin-bottom: ${theme.margin.smmd};
`

const TicketTitleAndPrice = styled.div`
	font-size: ${theme.fontSizes.md};
	font-weight: ${theme.fontWeight.semiBold};

	display: flex;
`

const TicketIcon = styled.div`
	margin-left: ${theme.margin.xxs};

	width: fit-content;
`

const TicketDescription = styled.p`
	font-size: ${theme.fontSizes.xs};

	width: 200px;
`

const TicketType = ({ ticket, promo, title = 'Full price', isPromo = false, isFirstTicket = true, key }) => {
	const ticketContext = useContext(TicketContext);
	const { price, convenienceFee, facilityFeePrice } = isPromo ? promo : ticket;
	useEffect(() => {
		if (isFirstTicket) {
			ticketContext.setSelectedTicket(ticket);
			ticketContext.setselectedTicketTitle(title);
			ticketContext.setSelectedTicketType(isPromo ? types.promo : types.public);
		}
	}, [])


	const addDecimals = (number) => {
		return Number(number).toFixed(2)
	}

	const handleClick = () => {
		let clickedTicket = {...ticket};
		clickedTicket.promotion = [];
		if(isPromo){
			clickedTicket.facilityFeePrice = promo.facilityFeePrice;
			clickedTicket.convenienceFeeBO = promo.convenienceFeeBO;
			clickedTicket.price = promo.price;
			clickedTicket.priceIncludingFees = promo.price+promo.facilityFeePrice+ticket.convenienceFeeBO;
			clickedTicket.promotionId = promo.promotionId;
			clickedTicket.promotionCode = promo.promotionCode;
			clickedTicket.selectedTicketRuleId = promo.ticketRuleId;
			clickedTicket.promotion = promo;
		} 
		ticketContext.setselectedTicketTitle(title);
		ticketContext.setSelectedTicket(clickedTicket);
	}
	let ticketDescription = facilityFeePrice > 0 ? "(Includes " : "";
	if(facilityFeePrice > 0){
		ticketDescription = ticketDescription.concat(`$${addDecimals(facilityFeePrice)} facility fee)`);
	}
	return (
		<Ticket onClick={handleClick} selected={ticketContext.selectedTicketTitle == title}>
			<TicketHeader>
				<TicketTitleAndPrice>
					{title}
					{isPromo && (
						<TicketIcon>
							<SvgStar color='black' size='10' />
						</TicketIcon>
					)}
				</TicketTitleAndPrice>

				<TicketTitleAndPrice>${addDecimals(price)}</TicketTitleAndPrice>
			</TicketHeader>
			<TicketDescription>
				{ticketDescription}
			</TicketDescription>

		</Ticket>
	)
}

export default TicketType
