import React from 'react'

// Icons
import { SvgClose } from 'components/common/icons/SvgIcons'

// Libraries
import styled from 'styled-components'

// Components
import SelectSeatGeneralInfo from './SelectSeatGeneralInfo'

// Styling
import { theme } from 'theme'

const PopUpHeader = styled.div`
	display: flex;
	justify-content: space-between;

	padding: ${theme.padding.lg} ${theme.padding.lg} 0 ${theme.padding.lg};
`

const PopUpHeaderTitle = styled.p`
	font-size: ${theme.fontSizes.md};
	font-weight: ${theme.fontWeight.regular};
`

const PopUpHeaderClose = styled.p`
	font-size: ${theme.fontSizes.md};

	cursor: pointer;
`

const SelectSeatDetails = ({ closePopup, selectedSeat }) => {
	return (
		<>
			<PopUpHeader>
				<PopUpHeaderTitle>Select ticket type</PopUpHeaderTitle>

				<PopUpHeaderClose onClick={closePopup}>
					<SvgClose />
				</PopUpHeaderClose>
			</PopUpHeader>

			<SelectSeatGeneralInfo selectedSeat={selectedSeat} />
		</>
	)
}

export default SelectSeatDetails
