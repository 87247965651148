import React from 'react';

import styled from 'styled-components';
import { motion } from "framer-motion"

// Themes and colors
import { theme, colors } from '../../theme';

const MainButton = styled.button`
	background-color: ${colors.primary.first};
	color: ${colors.white};

	border-radius: ${theme.borderRadius.lg};
	
	padding: ${theme.padding.sm} ${theme.padding.xl};

	width: fit-content;
	height: fit-content;

	cursor: pointer;

	font-size: ${theme.fontSizes.sm};

	border: 1px solid ${colors.primary.first};

	&:hover {
		background-color: ${colors.white};
		color: ${colors.primary.first};
	}
`;

const Button = ({ children, onClick }) => {
	return (
		<MainButton as={motion.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={onClick}>
			<span>{children}</span>
		</MainButton>
	);
}

export default Button;

