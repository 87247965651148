import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import SelectSeatDetails from "./SelectSeatDetails";
import TicketAccordion from "./accordion/TicketAccordion";
import Button from "components/buttons/Button";
import { theme, colors } from "theme";
import { TicketContext, types } from "./ticketContext/TicketContext";
import TicketType from "./TicketType";
import TicketPromoBanner from "./TicketPromoBanner";
import { addToCart } from "apis/cartWebSocketClient";
import Cookies from "js-cookie";

const Popup = styled.div`
  position: absolute;
  width: 400px;
  border: 1px solid ${colors.outlines.outline};
  box-shadow: ${theme.boxShadows.shadow5};
  border-radius: ${theme.borderRadius.md};
  background-color: ${colors.white};
  z-index: 9999;

  :after {
    position: absolute;
    content: "";
    bottom: -40px;
    height: 40px;
    width: 1px;
  }
`;

const ScrollableContainer = styled.div`
  overflow-y: scroll;
  max-height: 350px;
`;

const NoPromoTickets = styled.p`
  display: flex;
  justify-content: center;
  padding: ${theme.padding.lg};

  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSizes.md};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.padding.xl};
  border-top: 1px solid ${colors.outlines.outline};
`;

const SelectSeat = ({
  selectedSeat,
  promos,
  coordinates: position,
  onClosePopup,
  promoName = "",
  performanceId,
  setSelectedSeats,
}) => {
  const selectedSeatPopupRef = useRef();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [popupDimensions, setPopupDimensions] = useState({
    width: -3000,
    height: -3000,
  });
  const [coordinates, setCoordinates] = useState(position);
  const [isVisible, setIsVisible] = useState(false);

  const [selectedTicketType, setSelectedTicketType] = useState(types.public);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [collapsedAccordionOnce, setCollapsedAccordionOnce] = useState(false);
  const [ticketSelectedOnce, setTicketSelectedOnce] = useState(false);
  const [selectedTicketTitle, setselectedTicketTitle] = useState(null);

  useEffect(() => {
    if (selectedSeatPopupRef.current) {
      setPopupDimensions({
        width: selectedSeatPopupRef.current.offsetWidth,
        height: selectedSeatPopupRef.current.offsetHeight,
      });
    }
  }, [selectedSeatPopupRef.current]);

  const handleClickOutside = (event) => {
    if (
      selectedSeatPopupRef.current &&
      !selectedSeatPopupRef.current.contains(event.target) &&
      !event.target.closest("#viewportGroup")
    ) {
      closePopup();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("mouseup", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCoordinates(position);
    setIsVisible(true);
  }, [position]);

  const getActualPosition = () => {
    let adjustedCoordinates = [
      coordinates[0] - popupDimensions.width / 2,
      coordinates[1] - popupDimensions.height / 2,
    ];

    return adjustedCoordinates;
  };

  const closePopup = () => {
    setIsVisible(false);
    setTimeout(onClosePopup, 200);
  };

  const getLast8Characters = () => {
    const token = Cookies.get("token");
    if (typeof token !== "string" || token.length < 8) {
      throw new Error("Invalid token provided");
    }
    return token.slice(-8);
  };

  const handleAddToCart = async () => {
    const promotion = Array.isArray(selectedTicket.promotion)
        ? {}
        : selectedTicket.promotion || {};

    const promotionPrice = promotion?.facilityFeeApplied
        ? (promotion?.price || 0) + (selectedTicket?.facilityFeePrice || 0)
        : promotion?.price || 0;

    const seatObj = {
      sectionId: selectedSeat?.sectionId,
      selectedTicketTypeId: selectedTicket.ticketTypeId,
      selectedTicketRuleId:
        promotion?.ticketRuleId || selectedTicket.selectedTicketRuleId,
      promotionPrice: promotionPrice,
      promotionBOFee: promotion?.convenienceFeeBO || 0,
      promotionName: promotion?.name || null,
      name: selectedSeat.sectionName,
      promotionCode: promotion?.promotionCode || selectedTicket.promotionCode,
      promotionId: promotion?.promotionId || selectedTicket.promotionId,
      seats: selectedSeat.id,
      performanceId: performanceId,
      seatType: selectedSeat?.priceLevel?.type,
      seatTypeInitial: "available",
      type: "MSG",
      sessionId: getLast8Characters(),
      number: selectedSeat.number,
      row: selectedSeat.row,
      rowNumber: selectedSeat.rowNumber,
      selectedTicketName: selectedTicket.name,
    };
    try {
      const result = await addToCart(seatObj);
      if (result) setSelectedSeats();
    } catch (error) {
      console.error("Error handleAddToCart:", error);
    }
    closePopup();
  };

  const handleSetActiveAccordion = (ticket) => {
    setActiveAccordion((prev) => (prev === ticket ? null : ticket));
  };

  const [x, y] = getActualPosition();

  return (
    <Popup
      as={motion.div}
      ref={selectedSeatPopupRef}
      style={{ left: `${x}px`, top: `${y}px` }}
      initial={{ scale: 0, opacity: 0 }}
      animate={isVisible ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <SelectSeatDetails selectedSeat={selectedSeat} closePopup={closePopup} />
      <TicketContext.Provider
        value={{
          selectedTicketType,
          setSelectedTicketType,

          selectedTicket,
          setSelectedTicket,

					collapsedAccordionOnce,
					setCollapsedAccordionOnce,

          selectedTicketTitle,
          setselectedTicketTitle,
        }}
      >
        {promoName.trim().length < 1 ? (
          <AnimatePresence>      
            {selectedSeat.priceLevel.ticketTypes.map((ticket, index) => {
              let promotions = [];
              promos.map((promo, index) => {
                promo.ticketRules.map((promoDetail, index) => {
                  if (promoDetail.ticketTypeId == ticket.ticketTypeId) {
                    let promoTicket = {
                      name: promo.promotionName,
                      promotionCode: promo.promoCode,
                      promotionId: promo.promotionId,
                      ticketRuleId: promoDetail.id,
                      price: promoDetail.price + promoDetail.facilityFeePrice,
                      facilityFeeApplied: true,
                      facilityFeeName: promoDetail.facilityFeeName,
                      facilityFeePrice: promoDetail.facilityFeePrice,
                      convenienceFeeBO: promoDetail.consumerFeeBO,
                      priceIncludingFees: null,
                    };
                    promoTicket.priceIncludingFees =
                      promoTicket.price +
                      promoTicket.convenienceFeeBO +
                      promoTicket.facilityFeePrice;
                    promotions.push(promoTicket);
                  }
                });
              });
              ticket.promotion = promotions;
              ticket.promotionId = null;
              ticket.promotionCode = null;
              ticket.selectedTicketRuleId = null;

                return (<TicketAccordion
                  key={index}
                  ticket={ticket}
                  isFirstAccordion={!index}
                  isActive={activeAccordion === ticket}
                  handleSetActiveAccordion={handleSetActiveAccordion}
                />);
            })}
          </AnimatePresence>
        ) : (
          <ScrollableContainer>
            <TicketPromoBanner promoName={promoName} />
            {selectedSeat.priceLevel.ticketTypes.length > 0 ? (
              selectedSeat.priceLevel.ticketTypes.map((ticket, index) => (
                <TicketType
                  isFirstTicket={!index}
                  title={ticket.name}
                  key={index}
                  ticket={ticket}
                  isSelected={!index}
                />
              ))
            ) : (
              <NoPromoTickets>
                There are no promo tickets for this seat
              </NoPromoTickets>
            )}
          </ScrollableContainer>
        )}
        <ButtonContainer>
          <Button onClick={handleAddToCart}>Add to cart</Button>
        </ButtonContainer>
      </TicketContext.Provider>
    </Popup>
  );
};

export default SelectSeat;
