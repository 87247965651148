import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SeatingChart from 'components/seatingChart/seatSelection/SeatingChart';
import { ModalContainer } from 'components/common/modals/ModalContainer';
import Flex from 'components/common/containers/Flex';
import { LoadingIcon } from 'components/utils/LoadingView';
import { ChartConst, Layout } from 'util/Constants';
import "sass/components/seatingChart/SeatingChart.scss";
import seatingChartReducer from 'stores/calendar/SeatingChartStore';
import { useParams } from 'react-router-dom';
import SelectSeat from 'components/seatingChart/seatSelection/seat/SelectSeat';
import WebSocketClient from "./websocket/WebSocketClient";
import {
	selectTemporarySeat,
} from 'components/seatingChart/actions';
import 'sass/_global.scss';
import {getAllPromos} from "./apis/promotions";

const { CHART_PADDING, TOOLBAR_HEIGHT} = ChartConst;

const App = (props) => {

    const { clientId, performanceId, productionId } = useParams();
    const [hasAccSeating, setHasAccSeating] = useState(false);

    const [seatingChart, setSeatingChart] = useState(props.seatingChart && props.seatingChart.seatingChart || {});
	const [selectedSeats, setSelectedSeats] = useState({});

	const [allSelectedSeats, setAllSelectedSeats] = useState({});
	const [allSelectedSeatsArray, setAllSelectedSeatsArray] = useState([]);
	const [coordinates, setCoordinates] = useState({});

    const [, setLoading] = useState(true);
    const [, setError] = useState(null);
    const [currencySymbol] = useState("$");
	const [featureFlags, setFeatureFlags] = useState({});
	const [promos, setPromos] = useState([]);

    const handleWebSocketMessage = (message) => {
		if (!message.seatIds || message.seatIds.length === 0) {
			onSeatUnselect();
			updateSelectedSeats();
		}

		if (message.seatIds && message.seatIds.length > 0) {
			message.seatIds.forEach(seatId => {
				delete selectedSeats[seatId];
			});
			updateSelectedSeats();
		}
	};

    useEffect(() => {
        setHasAccSeating(true);
        const fetchSeatingChart = async () => {
            try {
                const seatingChart = await seatingChartReducer.getSeatingChart(performanceId, clientId, true);
                setSeatingChart(seatingChart);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

		const fetchPromos = async () => {
			try {
				const promos = await getAllPromos(performanceId);
				setPromos(promos);
			} catch (error) {
				setError(error);
			}
		};

        fetchSeatingChart();
		fetchPromos();
    }, []);

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.data && event.data.type === 'FEATURE_FLAGS') {
				const featureFlagsString = event.data.data;
				const featureFlags = JSON.parse(featureFlagsString);
				setFeatureFlags(featureFlags);
			}
		};

		window.addEventListener('message', handleMessage);
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

		const onSeatSelect = (data, seat, coordinates) => {
			let allSelectedSeats = Object.assign({}, { ...selectedSeats }, {[data.id] : data});
			setAllSelectedSeatsArray(prevArray => [...prevArray, data]);
			setAllSelectedSeats(allSelectedSeats);
			setCoordinates(coordinates);
		}

		const updateSelectedSeats = () => {
			setSelectedSeats(allSelectedSeats)
		}

		const onSeatUnselect = () => {
			setAllSelectedSeats({});
			setCoordinates([0, 0]);

			props.onSelectTemporarySeat({});
		}

    const renderSeatingChart = () => {

        if (Object.keys(seatingChart).length === 0) {
            return (
                <ModalContainer key="modalContainer" clear>
                    <LoadingIcon key="loading" size="lg" />
                </ModalContainer>
            );
        }

        const quantity = selectedSeats && Object.keys(selectedSeats).length;
        const calculatedSCViewPortSize = [
            window.innerWidth,
            window.innerHeight - Layout.min_navbar_height
        ];

		const selectedSeat = allSelectedSeatsArray[allSelectedSeatsArray.length - 1];

        return (
					<React.Fragment>
						<Flex
							className='containerSeatingChart'
							columns={2}
							fit={true}
							isDisabled={false}
							stretch
						>
							<SeatingChart
								key='seating_chart'
								clientId={clientId}
								data={seatingChart}
								disableOldWorkFlow={false}
								displayPriceLevelFilter={false}
								displayZoomButtons={true}
								hasAccSeating={hasAccSeating}
								onClickBack={() => {}}
								isDragging={() => {}}
								onSeatSelect={onSeatSelect}
								onSeatUnselect={onSeatUnselect}
								onSelectCartSeat={() => {}}
								performanceId={performanceId}
								productionId={productionId}
								productionSeatSelectionMethod={null}
								quantity={quantity}
								query={null}
								selectedSeats={selectedSeats}
								hidePopup={() => {}}
								noToolBar={false}
								isSmallDevice={false}
								isMobile={false}
								padding={{
									top: TOOLBAR_HEIGHT,
									right: CHART_PADDING,
									bottom: CHART_PADDING,
									left: CHART_PADDING,
								}}
								viewPortSize={calculatedSCViewPortSize}
								currencySymbol={currencySymbol}
								featureFlags={featureFlags}
							/>
						</Flex>
							{Object.keys(allSelectedSeats).length > 0 && <SelectSeat
								coordinates={coordinates}
								selectedSeat={selectedSeat}
								promoName=''
								promos={promos}
								onClosePopup={onSeatUnselect}
								performanceId={performanceId}
								setSelectedSeats={updateSelectedSeats}
							/>}
					</React.Fragment>
				)
    }

    return (
        <div
            className="ot_performanceSeatingChartView"
            style={{ overflow: 'hidden' }}
        >
            <React.Fragment>
                {renderSeatingChart()}
                <WebSocketClient onMessage={handleWebSocketMessage} />
            </React.Fragment>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
  onSelectTemporarySeat: (seat) => dispatch(selectTemporarySeat(seat)),
});

export default connect(null, mapDispatchToProps)(App);
